import Vue from 'vue'
import store from '@/store'

Vue.mixin({
	data() {
		return {
			drawer: false,
			about: [
				{
					img: 'about_1',
					id: 1
				},
				{
					img: 'about_2',
					id: 2
				},
				{
					img: 'about_3',
					id: 3
				},
			],
		}
	},
	computed: {
		isUserAuth: () => {
			return !!(store.state.user && Object.keys(store.state.user.userData).length)
		},
		state() {
			return store.getters
		},
		store() {
			return store
		},
		apiUpl() {
			return process.env.VUE_APP_API_URL ?? '/'
		},
	}
})
