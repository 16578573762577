export default {
  state: {
    positionSubmittings: {}
  },
  getters: {
    positionSubmittings: state => state.positionSubmittings
  },
  mutations: {
    SET_POSITIONS_SUBMITTING_LIST (state, positionSubmittings) {
      state.positionSubmittings = positionSubmittings
    }
  },
  actions: {
    setPositionsSubmittingList (context, positionSubmittings) {
      context.commit('SET_POSITIONS_SUBMITTING_LIST', positionSubmittings)
    }
  }
}
