import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'testimonials',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'Testimonials',
			component: () => import('@/pages/admin/testimonials/Testimonials.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'create',
			name: 'CreateTestimonial',
			component: () => import('@/pages/admin/testimonials/testimonials-create/Create.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'update/:id',
			name: 'UpdateTestimonial',
			component: () => import('@/pages/admin/testimonials/testimonials-update/Update.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'ShowTestimonial',
			component: () => import('@/pages/admin/testimonials/testimonials-show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
