import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import contactSubmitting from './modules/contactSubmitting'
import clientsLogos from './modules/clientsLogos'
import services from './modules/services'
import projects from './modules/projects'
import positionsSubmitting from './modules/positionsSubmitting'
import positions from './modules/positions'
import team from './modules/team'
import testimonials from './modules/testimonials'

Vue.use(Vuex)

const modules = {
  user,
  contactSubmitting,
  clientsLogos,
  services,
  projects,
  positionsSubmitting,
  positions,
  team,
  testimonials
}

export default new Vuex.Store({ modules: modules })
