'use strict'

import StorageHelper from '@/helpers/localStorageHelper'
import store from '@/store'

class ApiBase {
  static baseUrl() {
    return `${process.env.VUE_APP_API_URL}`
  }

  static defaultLang() {
    return `${process.env.VUE_APP_LANGUAGE}`
  }

  static authHeaders() {
    return {
      Accept: 'application/json',
      Authorization: 'Bearer ' + (StorageHelper.get('Token')),
      'Access-Control-Allow-Origin': '*'
    }
  }

  static unauthoriseUser(path) {
    this.setToStore(null, 'setUserDataList', {})
    StorageHelper.remove('Token')
    StorageHelper.remove('Fresh_token')
    window.location = path
  }

  static setToStore(self, name, data, callBack) {
    store.dispatch(name, data)
    if (callBack) {
      callBack()
    }
  }

}

export default ApiBase
