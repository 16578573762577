import RouterComponent from '@/components/router/Router'
import isLogin from "@/middleware/isLogin";

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/admin/login',
			name: 'Login',
			component: () => import('@/pages/admin/admin-auth/AdminAuth.vue'),
			meta: { middleware: [ isLogin ] }
		}
	]
}
