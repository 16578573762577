import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'Home',
			component: () => import('@/pages/home/Home.vue')
		}
	]
}
