export default {
  state: {
    positions: {}
  },
  getters: {
    positions: state => state.positions
  },
  mutations: {
    SET_POSITIONS_LIST (state, positions) {
      state.positions = positions
    }
  },
  actions: {
    setPositionsList (context, positions) {
      context.commit('SET_POSITIONS_LIST', positions)
    }
  }
}
