export default {
  state: {
    userData: {}
  },
  getters: {
    userData: state => state.userData
  },
  mutations: {
    SET_USER_DATA_LIST (state, user) {
      state.userData = user
    },
    MODIFY_USER_DATA_LIST (state, data) {
      for (var key in data) {
        state.userData[key] = data[key]
      }
    },
    CLEAR_USER_DATA_LIST (state) {
      state.userData = {}
    }
  },
  actions: {
    setUserDataList (context, user) {
      context.commit('SET_USER_DATA_LIST', user)
    },
    clearUserDataList (context, user) {
      context.commit('CLEAR_USER_DATA_LIST', user)
    },
    modifyUserDataList (context, data) {
      context.commit('MODIFY_USER_DATA_LIST', data)
    }
  }
}
