export default {
  state: {
    testimonials: {}
  },
  getters: {
    testimonials: state => state.testimonials
  },
  mutations: {
    SET_TESTIMONIALS_LIST (state, testimonials) {
      state.testimonials = testimonials
    }
  },
  actions: {
    setTestimonialsList (context, testimonials) {
      context.commit('SET_TESTIMONIALS_LIST', testimonials)
    }
  }
}
