export default {
  state: {
    team: {}
  },
  getters: {
    team: state => state.team
  },
  mutations: {
    SET_TEAM_LIST (state, team) {
      state.team = team
    }
  },
  actions: {
    setTeamList (context, team) {
      context.commit('SET_TEAM_LIST', team)
    }
  }
}
