import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'projects',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'Projects',
			component: () => import('@/pages/admin/projects/Projects.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'create',
			name: 'CreateProject',
			component: () => import('@/pages/admin/projects/create/Create.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'update/:id',
			name: 'UpdateProject',
			component: () => import('@/pages/admin/projects/update/Update.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'ShowProject',
			component: () => import('@/pages/admin/projects/show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
