import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'positions-submitting',
	component: RouterComponent,
	children: [
		{
			path: 'list',
			name: 'PositionSubmittingList',
			component: () => import('@/pages/admin/positions-submitting/PositionsSubmitting'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'PositionSubmittingShow',
			component: () => import('@/pages/admin/positions-submitting/show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
