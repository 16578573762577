import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'works',
			name: 'Works',
			component: () => import('@/pages/works/Works.vue')
		},
		{
			path: 'works/:urlName',
			name: 'Work',
			component: () => import('@/components/works/work/Work.vue')
		}
	]
}
