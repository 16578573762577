import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/admin',
			name: 'Admin',
			component: () => import('@/pages/admin/admin/Admin'),
			meta: { middleware: [ auth ] }
		}
	]
}
