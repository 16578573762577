import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'team-list',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'TeamList',
			component: () => import('@/pages/admin/team/Team.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'create',
			name: 'CreateTeam',
			component: () => import('@/pages/admin/team/create/Create.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'update/:id',
			name: 'UpdateTeam',
			component: () => import('@/pages/admin/team/update/Update.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'ShowTeam',
			component: () => import('@/pages/admin/team/show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
