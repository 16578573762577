export default {
  state: {
    projects: {}
  },
  getters: {
    projects: state => state.projects
  },
  mutations: {
    SET_PROJECTS_LIST (state, projects) {
      state.projects = projects
    }
  },
  actions: {
    setProjectsList (context, projects) {
      context.commit('SET_PROJECTS_LIST', projects)
    }
  }
}
