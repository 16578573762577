export default {
  state: {
    clientsLogos: {}
  },
  getters: {
    clientsLogos: state => state.clientsLogos
  },
  mutations: {
    SET_CLIENTS_LOGOS_LIST (state, clientsLogos) {
      state.clientsLogos = clientsLogos
    }
  },
  actions: {
    setClientsLogosList (context, clientsLogos) {
      context.commit('SET_CLIENTS_LOGOS_LIST', clientsLogos)
    }
  }
}
