import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'about',
			name: 'About',
			component: () => import('@/pages/about/About.vue')
		},
	]
}
