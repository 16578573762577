import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'services',
			name: 'Services',
			component: () => import('@/pages/services/Services.vue')
		},
		{
			path: 'services/:urlName',
			name: 'Service',
			component: () => import('@/components/services/service/Service.vue')
		}
	]
}
