import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'services',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'AdminServices',
			component: () => import('@/pages/admin/services/Services.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'create',
			name: 'CreateService',
			component: () => import('@/pages/admin/services/create/Create.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'update/:id',
			name: 'UpdateService',
			component: () => import('@/pages/admin/services/update/Update.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'ShowService',
			component: () => import('@/pages/admin/services/show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
