import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'clients-logos',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'ClientsLogos',
			component: () => import('@/pages/admin/clients-logos/ClientsLogos.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
