import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'team',
			name: 'Team',
			component: () => import('@/pages/team/Team.vue')
		},
		{
			path: 'team/:urlName',
			name: 'TeamDetail',
			component: () => import('@/components/team/DetailsTeam.vue')
		}
	]
}
