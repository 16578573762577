import storageHelper from "@/helpers/localStorageHelper";

export default function isLogin ({ next }) {
  if (storageHelper.get('Token')) {
    return next({
      name: 'Admin'
    })
  }
  return next()
}
