import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

//import routes
import Home from './routes/home'
import Works from './routes/works'
import About from './routes/about'
import Team from './routes/team'
import Career from './routes/career'
import Contact from './routes/contact'
import NotFound from './routes/not-found'
import Services from './routes/services'
import PrivacyPolicy from './routes/privacy-policy'
import TermConditions from './routes/term-conditions'
import ThankYou from './routes/thank-you'

// #admin
import Login from './routes/admin/admin-auth'
import Admin from './routes/admin/admin'
import ClientsLogos from './routes/admin/clients-logos'
import ContactSubmitting from './routes/admin/contact-submitting'
import AdminServices from './routes/admin/admin-services'
import Projects from './routes/admin/admin-projects'
import PositionsSubmitting from './routes/admin/positions-submitting'
import AdminPositions from './routes/admin/admin-positions'
import AdminTeam from './routes/admin/admin-team'
import Testimonials from './routes/admin/testimonials'
import Settings from  './routes/admin/settings'
import AdminAbout from  './routes/admin/about'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/admin',
			component: () => import('@/components/admin-container/AdminContainer'),
			children: [
				Admin,
				Login,
				ContactSubmitting,
				ClientsLogos,
				AdminServices,
				Projects,
				PositionsSubmitting,
				AdminPositions,
				AdminTeam,
				Testimonials,
				Settings,
				AdminAbout
			]
		},
		{
			path: '/',
			component: () => import('@/components/main-container/MainContainer'),
			children: [
				Home,
				Services,
				Works,
				About,
				Team,
				Career,
				Contact,
				PrivacyPolicy,
				TermConditions,
				ThankYou,
				NotFound
			]
		}
	]
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)

	if (!to.meta.middleware) {
		return next()
	}

	const middleware = to.meta.middleware

	if (middleware.length === 0) {
		return next()
	}
	const context = {
		to,
		from,
		next,
		store
	}
	for (var key in middleware) {
		middleware[key]({
			...context
		})
	}
})

export default router
