import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

var VueScrollTo = require('vue-scrollto');
var VueCookie = require('vue-cookie');

// import css
import 'vuetify/dist/vuetify.min.css'
import '@/assets/scss/app.scss'

// import plugins
import './plugins/axios'
import vuetify from './plugins/vuetify'

// requires
require('@/plugins/vueMixin')

Vue.use(Notifications)
Vue.use(store)
Vue.use(require('vue-moment'))
Vue.use(VueMeta, {refreshOnceOnNavigation: true})
Vue.use(VueGtag, {
	config: { id: process.env.VUE_APP_GTAG_ID }
}, router);

// You can also pass in the default options
Vue.use(VueScrollTo, {
	container: "body",
	duration: 500,
	easing: "ease",
	offset: -125,
	force: true,
	lazy: false,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
})
Vue.use(VueCookie);


Vue.config.productionTip = false

new Vue({
	router,
	vuetify,
	axios,
	VueAxios,
	render: h => h(App)
}).$mount('#app')
