import storageHelper from '@/helpers/localStorageHelper'

export default function auth({next}) {
	if (!storageHelper.get('Token')) {
		return next({
			name: 'Login'
		})
	}
	return next()
}
