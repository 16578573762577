import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'positions',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'Positions',
			component: () => import('@/pages/admin/positions/Positions.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'create',
			name: 'CreatePosition',
			component: () => import('@/pages/admin/positions/create/Create.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'update/:id',
			name: 'UpdatePosition',
			component: () => import('@/pages/admin/positions/update/Update.vue'),
			meta: { middleware: [ auth ] }
		},
		{
			path: 'show/:id',
			name: 'ShowPosition',
			component: () => import('@/pages/admin/positions/show/Show.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
