export default {
  state: {
    services: {}
  },
  getters: {
    services: state => state.services
  },
  mutations: {
    SET_SERVICES_LIST (state, services) {
      state.services = services
    }
  },
  actions: {
    setServicesList (context, services) {
      context.commit('SET_SERVICES_LIST', services)
    }
  }
}
