import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'term-conditions',
			name: 'TermConditions',
			component: () => import('@/pages/term-conditions/TermConditions.vue')
		},
	]
}
