import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'contact-submitting',
	component: RouterComponent,
	children: [
		{
			path: 'list',
			name: 'ContactSubmittingList',
			component: () => import('@/pages/admin/contact-submitting/ContactSubmitting'),
			meta: { middleware: [ auth ] }
		}
	]
}
