import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: 'admin-about',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'AdminAbout',
			component: () => import('@/pages/admin/about/About.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
