export default {
  state: {
    contactSubmitting: {}
  },
  getters: {
    contactSubmitting: state => state.contactSubmitting
  },
  mutations: {
    SET_CONTACT_SUBMITTING_LIST (state, contactSubmitting) {
      state.contactSubmitting = contactSubmitting
    }
  },
  actions: {
    setContactSubmittingList (context, contactSubmitting) {
      context.commit('SET_CONTACT_SUBMITTING_LIST', contactSubmitting)
    }
  }
}
