import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: 'careers',
			name: 'Careers',
			component: () => import('@/pages/career/Career.vue')
		},
		{
			path: 'apply/:urlName',
			name: 'Apply',
			component: () => import('@/components/careers/apply/Apply.vue')
		},
		{
			path: 'careers/:urlName',
			name: 'CareersPosition',
			component: () => import('@/components/careers/career-position/CareerPosition.vue')
		},
	]
}
