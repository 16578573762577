'use strict'

import Vue from 'vue'
import {notify} from '@/helpers/notifyHelper'
import axios from 'axios'
import ApiBase from "@/api/base";

Vue.mixin({
	methods: {
		http(method, url, params, header) {
			method = method.toLowerCase()
			const config = {
				method: method,
				url
			}

			if (method === 'get') {
				config.params = params
			} else if (method === 'post' || method === 'put' || method === 'delete') {
				config.data = params
			}

			config.headers = Object.assign(ApiBase.authHeaders(), header || {})

			return new Promise((resolve, reject) => {
				return axios(config)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response) {
							switch (error.response.status) {
								case 401:
									notify(this, 'error', error.response.data.message)
									ApiBase.unauthoriseUser('/admin/login')
									break
								case 403:
									notify(this, 'error', error.response.data.message)
									reject(error)
									break
								default:
									notify(this, 'error', error.response.data.message)
									// reject(error)
									break
							}
						} else {
							console.error(error)
							reject(error)
						}
					})
			})
		}
	}
})
